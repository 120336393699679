import { ExpeditionRepository } from "../../../repositories/defaultUserRepositories/ExpeditionRepository";
import router from "../../../router";

export default {
  async expedition({ commit, state }) {
    commit("setListingExpedition", []);
    commit("setExpeditionPagination", []);
    const urlParams = state.urlParams;
    let url = `/coleta/listar?page=${urlParams.page}&perPage=${10}&unidade=1`;
    if (urlParams.search) {
      url = url.concat(`&search=${urlParams.search}`);
    }
    if (urlParams.codigo) {
      url = url.concat(`&codigo=${urlParams.codigo}`);
    }
    if (urlParams.currentDay) {
      url = url.concat(
        `&initialDate=${urlParams.currentDay}&finalDate=${urlParams.currentDay}`
      );
    }
    if (urlParams.initialDate) {
      url = url.concat(`&initialDate=${urlParams.initialDate}`);
    }
    if (urlParams.finalDate) {
      url = url.concat(`&finalDate=${urlParams.finalDate}`);
    }
    const response = await ExpeditionRepository.requestExpedition(url);
    if (response.status >= 200 && response.status < 300) {
      commit("setListingExpedition", response.data.data.list);
      commit("setExpeditionPagination", response.data.data.pagination);
      return;
    }
    let errorObj = {
      errorStatus: true,
      errorMessage: response.data.message,
    };
    commit("notifications/setErrorNotification", errorObj, { root: true });
  },

  async collectRequests({ commit }, coletaId) {
    commit("setIsLoadingPickupDetails", true);
    commit("setCollectRequests", []);
    const response = await ExpeditionRepository.collectRequests(coletaId);
    if (response.status >= 200 && response.status < 300) {
      let successObj = {
        successStatus: true,
        successMessage: "Detalhes e pedidos encontrados com sucesso!",
      };
      commit("setCollectRequests", response.data.data);
      commit("notifications/setSuccessNotification", successObj, {
        root: true,
      });
      commit("setIsLoadingPickupDetails", false);
      return;
    }
    let errorObj = {
      errorStatus: true,
      errorMessage: response.data.message,
    };
    commit("setCollectRequests", response.data.data);
    commit("notifications/setErrorNotification", errorObj, { root: true });
    commit("setIsLoadingPickupDetails", false);
  },

  async listForklifts({ commit }) {
    const response = await ExpeditionRepository.listForklifts();
    if (response.status >= 200 && response.status < 300) {
      commit("setForklifts", response.data.data);
      return;
    } else {
      let errorObj = {
        errorStatus: true,
        errorMessage: "Ocorreu um erro na aplicação",
      };
      commit("notifications/setErrorNotification", errorObj, { root: true });
    }
  },

  async listDocks({ commit }) {
    const response = await ExpeditionRepository.listDocks();
    if (response.status >= 200 && response.status < 300) {
      commit("setDocks", response.data.data);
      return;
    } else {
      let errorObj = {
        errorStatus: true,
        errorMessage: "Ocorreu um erro na aplicação",
      };
      commit("notifications/setErrorNotification", errorObj, { root: true });
    }
  },

  async listDrivers({ commit }) {
    const response = await ExpeditionRepository.listDrivers();
    if (response.status >= 200 && response.status < 300) {
      commit("setDrivers", response.data.data);
      return;
    } else {
      let errorObj = {
        errorStatus: true,
        errorMessage: "Ocorreu um erro na aplicação",
      };
      commit("notifications/setErrorNotification", errorObj, { root: true });
    }
  },

  // empilhador_pcempr_id: this.selectForklifts.empilhadorId,
  // protocolo: this.protocolModel,
  // coleta_id: this.coletaId,
  // doca_id: this.getProtocolDetails.docaId,
  // motorista_id:
  async sendBoarding(
    { commit },
    { empilhador_pcempr_id, protocolo, coleta_id, doca_id, motorista_id }
  ) {
    let data = {
      empilhador_pcempr_id,
      protocolo,
      coleta_id,
      doca_id,
      motorista_id,
    };
    const response = await ExpeditionRepository.sendBoarding(data);
    if (response.status >= 200 && response.status < 300) {
      commit(
        "notifications/setSuccessNotification",
        {
          successStatus: true,
          successMessage: "Embarque iniciado com sucesso!",
        },
        {
          root: true,
        }
      );
      return true;
      // await this.$router.push({
      //   name: "EmbarqueColeta",
      //   params: {coletaId: coleta_id},
      // });
    } else {
      commit(
        "notifications/setErrorNotification",
        {
          errorStatus: true,
          errorMessage: response.data.message,
        },
        { root: true }
      );
    }

    // HttpRestService.post("/coleta/iniciar/embarque", modelSendBoarding).then(
    //   (response) => {
    //     if (response.status >= 200 && response.status < 300) {
    //       commit("notifications/setSuccessNotification", successObj, {
    //         root: true,
    //       });
    //       router.push({
    //         name: "Manifesto",
    //         params: { id: modelSendBoarding.protocolo },
    //       });
    //
    //       return;
    //     }
    //     let errorObj = {
    //       errorStatus: true,
    //       errorMessage: response.data.message,
    //     };
    //     commit("notifications/setErrorNotification", errorObj, { root: true });
    //   }
    // );
  },

  async continueBoarding({ commit }, modelContinueBoarding) {
    const response = await ExpeditionRepository.requestContinueBoarding(
      modelContinueBoarding
    );
    if (response.status >= 200 && response.status < 300) {
      commit(
        "notifications/setSuccessNotification",
        {
          successStatus: true,
          successMessage: "Embarque continuado com sucesso!",
        },
        {
          root: true,
        }
      );
      return true;
    } else {
      commit(
        "notifications/setErrorNotification",
        {
          errorStatus: true,
          errorMessage: response.data.message,
        },
        { root: true }
      );
    }
  },

  setIsLoading({ commit }, value) {
    commit("setIsLoading", value);
  },

  setUrlParams({ commit }, value) {
    commit("setUrlParams", value);
  },

  async protocolDetails({ commit }, protocol) {
    commit("setProtocolDetails", []);
    const response = await ExpeditionRepository.protocolDetails(protocol);
    if (response.status >= 200 && response.status < 300) {
      commit("setProtocolDetails", response.data.data[0]);
      return;
    } else {
      let errorObj = {
        errorStatus: true,
        errorMessage: response.data.message,
      };
      commit("notifications/setErrorNotification", errorObj, { root: true });
      return;
    }
  },

  async receptionDetails({ commit }, body) {
    const response = await ExpeditionRepository.receptionDetails(body);
    if (response.status >= 200 && response.status < 300) {
      return true;
    } else {
      let errorObj = {
        errorStatus: true,
        errorMessage: response.data.message,
      };
      commit("notifications/setErrorNotification", errorObj, { root: true });
      return;
    }
  },

  async findColetaDetalhesByColetaId({ commit }, coletaId) {
    commit("setColetaDetalhes", {});
    const response =
      await ExpeditionRepository.findColetaDetalhesByColetaIdEmbarque(coletaId);
    if (response.status >= 200 && response.status < 300) {
      const { data } = response.data;
      const coletaDetalhes = {
        coletaId: data.coletaId,
        dataInicioEmbarque: data.dataInicioEmbarque || "0000-00-00 00:00:00",
        dataFimEmbarque: data.dataFimEmbarque || "0000-00-00 00:00:00",
        coletaStatus: data.coletaStatus || "",
        despachanteId: data.despachanteId,
        despachanteNome: data.despachanteNome || "",
        empilhadorId: data.empilhadorId,
        empilhadorNome: data.empilhadorNome,
        transportadoraId: data.transportadoraId,
        transportadoraNomeFantasia: data.transportadoraNomeFantasia || "",
        motoristaId: data.motoristaId,
        motoristaCpf: data.motoristaCpf || "",
        motoristaNome: data.motoristaNome || "",
        veiculoId: data.veiculoId,
        veiculoPlaca: data.veiculoPlaca || "",
        tipoVeiculoId: data.tipoVeiculoId,
        tipoVeiculoNome: data.tipoVeiculoNome || "",
        docaId: data.docaId,
        docaCor: data.docaCor || "",
        docaCodigo: data.docaCodigo,
        quantidadeVolumesEmbarcados: data.quantidadeVolumesEmbarcados,
        quantidadeVolumesTotal: data.quantidadeVolumesTotal,
        quantidadePedidos: data.quantidadePedidos,
        permiteColetaAssinaturaMotorista:
          !!data.permiteColetaAssinaturaMotorista,
        permiteFinalizarColeta: !!data.permiteFinalizarColeta,
        assinaturaMotoristaUrl: data.assinaturaMotoristaUrl || "",
        pedidos: data.pedidos.map((p) => ({
          pedidoId: p.pedidoId,
          numeroPedido: p.numeroPedido,
          tipoPedido: p.tipoPedido,
          quantidadeVolumesEmbarcados: p.quantidadeVolumesEmbarcados,
          quantidadeVolumesTotal: p.quantidadeVolumesTotal,
        })),
        volumes: data.volumes.map((v) => ({
          volumeId: v.volumeId,
          pedidoId: v.pedidoId,
          codigoBarras: v.codigoBarras || "",
          codigoCarga: v.codigoCarga || "",
          habilitaEditarDetalhes: !!v.habilitaEditarDetalhes,
          permiteBipagem: !!v.permiteBipagem,
          numeroPedido: v.numeroPedido,
          embarcado: !!v.embarcado,
          cargaId: v.cargaId,
          cargaStatus: v.cargaStatus || "",
          tipoPedido: v.tipoPedido || "",
        })),
        despachanteLista: data.despachanteLista.map((v) => ({
          despachanteNome: v.despachanteNome || "",
          despachanteId: v.despachanteId,
        })),
        tipoColeta: data.tipoColeta,
        existePedidoNaoFaturado: data.existePedidoNaoFaturado,
        pedidosNaoFaturados: data.pedidosNaoFaturados,
      };
      if (data.volumes.length === 0) {
        let errorObj = {
          errorStatus: true,
          errorMessage: "Esta coleta não possui volumes.",
        };
        commit("notifications/setErrorNotification", errorObj, { root: true });
      }
      console.log({ coletaDetalhes });
      commit("setColetaDetalhes", coletaDetalhes);
      return;
    } else {
      let errorObj = {
        errorStatus: true,
        errorMessage: response.data.message,
      };
      commit("notifications/setErrorNotification", errorObj, { root: true });
    }
  },

  async findColetaDetalhesRedespacho({ commit }, coletaId) {
    commit("setColetaDetalhes", {});
    const response = await ExpeditionRepository.findColetaDetalhesRedespacho(
      coletaId
    );
    if (response.status >= 200 && response.status < 300) {
      const { data } = response.data;
      const coletaDetalhes = {
        coletaId: data.coletaId,
        dataInicioEmbarque: data.dataInicioEmbarque || "0000-00-00 00:00:00",
        dataFimEmbarque: data.dataFimEmbarque || "0000-00-00 00:00:00",
        dataInicioRecebimento:
          data.dataInicioRecebimento || "0000-00-00 00:00:00",
        coletaStatus: data.coletaStatus || "",
        despachanteId: data.despachanteId,
        despachanteNome: data.despachanteNome || "",
        empilhadorId: data.empilhadorId,
        empilhadorNome: data.empilhadorNome,
        transportadoraId: data.transportadoraId,
        transportadoraNomeFantasia: data.transportadoraNomeFantasia || "",
        motoristaId: data.motoristaId,
        motoristaCpf: data.motoristaCpf || "",
        motoristaNome: data.motoristaNome || "",
        veiculoId: data.veiculoId,
        veiculoPlaca: data.veiculoPlaca || "",
        tipoVeiculoId: data.tipoVeiculoId,
        tipoVeiculoNome: data.tipoVeiculoNome || "",
        docaId: data.docaId,
        docaCor: data.docaCor || "",
        docaCodigo: data.docaCodigo,
        quantidadeVolumesEmbarcados: data.quantidadeVolumesEmbarcados,
        quantidadeVolumesTotal: data.quantidadeVolumesTotal,
        quantidadePedidos: data.quantidadePedidos,
        permiteColetaAssinaturaMotorista:
          !!data.permiteColetaAssinaturaMotorista,
        permiteFinalizarColeta: !!data.permiteFinalizarColeta,
        assinaturaMotoristaUrl: data.assinaturaMotoristaUrl || "",
        pedidos: data.pedidos.map((p) => ({
          pedidoId: p.pedidoId,
          numeroPedido: p.numeroPedido,
          tipoPedido: p.tipoPedido,
          quantidadeVolumesEmbarcados: p.quantidadeVolumesEmbarcados,
          quantidadeVolumesTotal: p.quantidadeVolumesTotal,
        })),
        volumes: data.volumes.map((v) => ({
          volumeId: v.volumeId,
          pedidoId: v.pedidoId,
          codigoBarras: v.codigoBarras || "",
          codigoCarga: v.codigoCarga || "",
          habilitaEditarDetalhes: !!v.habilitaEditarDetalhes,
          permiteBipagem: !!v.permiteBipagem,
          numeroPedido: v.numeroPedido,
          embarcado: !!v.embarcado,
          redespachado: !!v.redespachado,
          cargaId: v.cargaId,
          cargaStatus: v.cargaStatus || "",
          tipoPedido: v.tipoPedido || "",
        })),
        despachanteLista: data.despachanteLista.map((v) => ({
          despachanteNome: v.despachanteNome || "",
          despachanteId: v.despachanteId,
        })),
      };
      if (data.volumes.length === 0) {
        let errorObj = {
          errorStatus: true,
          errorMessage: "Esta coleta não possui volumes.",
        };
        commit("notifications/setErrorNotification", errorObj, { root: true });
      }
      console.log({ coletaDetalhes });
      commit("setColetaDetalhes", coletaDetalhes);
      return;
    } else {
      let errorObj = {
        errorStatus: true,
        errorMessage: response.data.message,
      };
      commit("notifications/setErrorNotification", errorObj, { root: true });
    }
  },

  async findColetaDetalhesRecebimento({ commit }, coletaId) {
    commit("setColetaDetalhes", {});
    const response = await ExpeditionRepository.findColetaDetalhesRecebimento(
      coletaId
    );
    if (response.status >= 200 && response.status < 300) {
      const { data } = response.data;
      const coletaDetalhes = {
        coletaId: data.coletaId,
        recebimentoId: data.recebimentoId,
        dataInicioEmbarque: data.dataInicioEmbarque || "0000-00-00 00:00:00",
        dataFimEmbarque: data.dataFimEmbarque || "0000-00-00 00:00:00",
        dataInicioRecebimento:
          data.dataInicioRecebimento || "0000-00-00 00:00:00",
        coletaStatus: data.coletaStatus || "",
        despachanteId: data.despachanteId,
        despachanteNome: data.despachanteNome || "",
        empilhadorId: data.empilhadorId,
        empilhadorNome: data.empilhadorNome,
        transportadoraId: data.transportadoraId,
        transportadoraNomeFantasia: data.transportadoraNomeFantasia || "",
        motoristaId: data.motoristaId,
        motoristaCpf: data.motoristaCpf || "",
        motoristaNome: data.motoristaNome || "",
        veiculoId: data.veiculoId,
        veiculoPlaca: data.veiculoPlaca || "",
        tipoVeiculoId: data.tipoVeiculoId,
        tipoVeiculoNome: data.tipoVeiculoNome || "",
        docaId: data.docaId,
        docaCor: data.docaCor || "",
        docaCodigo: data.docaCodigo,
        quantidadeVolumesEmbarcados: data.quantidadeVolumesEmbarcados,
        quantidadeVolumesTotal: data.quantidadeVolumesTotal,
        quantidadePedidos: data.quantidadePedidos,
        permiteColetaAssinaturaMotorista:
          !!data.permiteColetaAssinaturaMotorista,
        permiteFinalizarColeta: !!data.permiteFinalizarColeta,
        assinaturaMotoristaUrl: data.assinaturaMotoristaUrl || "",
        pedidos: data.pedidos.map((p) => ({
          pedidoId: p.pedidoId,
          numeroPedido: p.numeroPedido,
          tipoPedido: p.tipoPedido,
          quantidadeVolumesEmbarcados: p.quantidadeVolumesEmbarcados,
          quantidadeVolumesTotal: p.quantidadeVolumesTotal,
        })),
        volumes: data.volumes.map((v) => ({
          volumeId: v.volumeId,
          pedidoId: v.pedidoId,
          codigoBarras: v.codigoBarras || "",
          codigoCarga: v.codigoCarga || "",
          habilitaEditarDetalhes: !!v.habilitaEditarDetalhes,
          permiteBipagem: !!v.permiteBipagem,
          numeroPedido: v.numeroPedido,
          embarcado: !!v.embarcado,
          recebido: !!v.recebido,
          cargaId: v.cargaId,
          cargaStatus: v.cargaStatus || "",
          tipoPedido: v.tipoPedido || "",
        })),
        despachanteLista: data.despachanteLista.map((v) => ({
          despachanteNome: v.despachanteNome || "",
          despachanteId: v.despachanteId,
        })),
      };
      if (data.volumes.length === 0) {
        let errorObj = {
          errorStatus: true,
          errorMessage: "Esta coleta não possui volumes.",
        };
        commit("notifications/setErrorNotification", errorObj, { root: true });
      }
      console.log({ coletaDetalhes });
      commit("setColetaDetalhes", coletaDetalhes);
      return;
    } else {
      let errorObj = {
        errorStatus: true,
        errorMessage: response.data.message,
      };
      commit("notifications/setErrorNotification", errorObj, { root: true });
    }
  },

  async reloadColetaDetalhesByColetaId({ commit }, coletaId) {
    commit("setIsLoadingReload", true);
    const response =
      await ExpeditionRepository.findColetaDetalhesByColetaIdEmbarque(coletaId);
    if (response.status >= 200 && response.status < 300) {
      console.log({ response });
      const { data } = response.data;
      const coletaDetalhes = {
        coletaId: data.coletaId,
        dataInicioEmbarque: data.dataInicioEmbarque || "0000-00-00 00:00:00",
        dataFimEmbarque: data.dataFimEmbarque || "0000-00-00 00:00:00",
        coletaStatus: data.coletaStatus || "",
        despachanteId: data.despachanteId,
        despachanteNome: data.despachanteNome || "",
        empilhadorId: data.empilhadorId,
        transportadoraId: data.transportadoraId,
        transportadoraNomeFantasia: data.transportadoraNomeFantasia || "",
        motoristaId: data.motoristaId,
        motoristaCpf: data.motoristaCpf || "",
        motoristaNome: data.motoristaNome || "",
        veiculoId: data.veiculoId,
        veiculoPlaca: data.veiculoPlaca || "",
        tipoVeiculoId: data.tipoVeiculoId,
        tipoVeiculoNome: data.tipoVeiculoNome || "",
        docaId: data.docaId,
        docaCor: data.docaCor || "",
        docaCodigo: data.docaCodigo,
        quantidadeVolumesEmbarcados: data.quantidadeVolumesEmbarcados,
        quantidadeVolumesTotal: data.quantidadeVolumesTotal,
        quantidadePedidos: data.quantidadePedidos,
        permiteColetaAssinaturaMotorista:
          !!data.permiteColetaAssinaturaMotorista,
        permiteFinalizarColeta: !!data.permiteFinalizarColeta,
        assinaturaMotoristaUrl: data.assinaturaMotoristaUrl || "",
        pedidos: data.pedidos.map((p) => ({
          pedidoId: p.pedidoId,
          numeroPedido: p.numeroPedido,
          tipoPedido: p.tipoPedido,
          quantidadeVolumesEmbarcados: p.quantidadeVolumesEmbarcados,
          quantidadeVolumesTotal: p.quantidadeVolumesTotal,
        })),
        volumes: data.volumes.map((v) => ({
          volumeId: v.volumeId,
          pedidoId: v.pedidoId,
          codigoBarras: v.codigoBarras || "",
          codigoCarga: v.codigoCarga || "",
          habilitaEditarDetalhes: !!v.habilitaEditarDetalhes,
          permiteBipagem: !!v.permiteBipagem,
          numeroPedido: v.numeroPedido,
          embarcado: !!v.embarcado,
          cargaId: v.cargaId,
          cargaStatus: v.cargaStatus || "",
          tipoPedido: v.tipoPedido || "",
        })),
        tipoColeta: data.tipoColeta,
        existePedidoNaoFaturado: data.existePedidoNaoFaturado,
        pedidosNaoFaturados: data.pedidosNaoFaturados,
      };
      commit("setColetaDetalhes", coletaDetalhes);
      commit("setIsLoadingReload", false);
      return;
    } else {
      let errorObj = {
        errorStatus: true,
        errorMessage: response.data.message,
      };
      commit("notifications/setErrorNotification", errorObj, { root: true });
      commit("setIsLoadingReload", false);
      return null;
    }
  },

  async reloadColetaDetalhesRecebimento({ commit }, coletaId) {
    commit("setIsLoadingReload", true);
    const response = await ExpeditionRepository.findColetaDetalhesRecebimento(
      coletaId
    );
    if (response.status >= 200 && response.status < 300) {
      console.log({ response });
      const { data } = response.data;
      const coletaDetalhes = {
        coletaId: data.coletaId,
        recebimentoId: data.recebimentoId,
        dataInicioEmbarque: data.dataInicioEmbarque || "0000-00-00 00:00:00",
        dataFimEmbarque: data.dataFimEmbarque || "0000-00-00 00:00:00",
        dataInicioRecebimento:
          data.dataInicioRecebimento || "0000-00-00 00:00:00",
        coletaStatus: data.coletaStatus || "",
        despachanteId: data.despachanteId,
        despachanteNome: data.despachanteNome || "",
        empilhadorId: data.empilhadorId,
        transportadoraId: data.transportadoraId,
        transportadoraNomeFantasia: data.transportadoraNomeFantasia || "",
        motoristaId: data.motoristaId,
        motoristaCpf: data.motoristaCpf || "",
        motoristaNome: data.motoristaNome || "",
        veiculoId: data.veiculoId,
        veiculoPlaca: data.veiculoPlaca || "",
        tipoVeiculoId: data.tipoVeiculoId,
        tipoVeiculoNome: data.tipoVeiculoNome || "",
        docaId: data.docaId,
        docaCor: data.docaCor || "",
        docaCodigo: data.docaCodigo,
        quantidadeVolumesEmbarcados: data.quantidadeVolumesEmbarcados,
        quantidadeVolumesTotal: data.quantidadeVolumesTotal,
        quantidadePedidos: data.quantidadePedidos,
        permiteColetaAssinaturaMotorista:
          !!data.permiteColetaAssinaturaMotorista,
        permiteFinalizarColeta: !!data.permiteFinalizarColeta,
        assinaturaMotoristaUrl: data.assinaturaMotoristaUrl || "",
        pedidos: data.pedidos.map((p) => ({
          pedidoId: p.pedidoId,
          numeroPedido: p.numeroPedido,
          tipoPedido: p.tipoPedido,
          quantidadeVolumesEmbarcados: p.quantidadeVolumesEmbarcados,
          quantidadeVolumesTotal: p.quantidadeVolumesTotal,
        })),
        volumes: data.volumes.map((v) => ({
          volumeId: v.volumeId,
          pedidoId: v.pedidoId,
          codigoBarras: v.codigoBarras || "",
          codigoCarga: v.codigoCarga || "",
          habilitaEditarDetalhes: !!v.habilitaEditarDetalhes,
          permiteBipagem: !!v.permiteBipagem,
          numeroPedido: v.numeroPedido,
          embarcado: !!v.embarcado,
          recebido: !!v.recebido,
          cargaId: v.cargaId,
          cargaStatus: v.cargaStatus || "",
          tipoPedido: v.tipoPedido || "",
        })),
      };
      console.log({ coletaDetalhes });
      commit("setColetaDetalhes", coletaDetalhes);
      commit("setIsLoadingReload", false);
      return;
    } else {
      let errorObj = {
        errorStatus: true,
        errorMessage: response.data.message,
      };
      commit("notifications/setErrorNotification", errorObj, { root: true });
      commit("setIsLoadingReload", false);
      return null;
    }
  },

  async reloadColetaDetalhesRedespacho({ commit }, coletaId) {
    commit("setIsLoadingReload", true);
    const response = await ExpeditionRepository.findColetaDetalhesRedespacho(
      coletaId
    );
    if (response.status >= 200 && response.status < 300) {
      console.log({ response });
      const { data } = response.data;
      const coletaDetalhes = {
        coletaId: data.coletaId,
        recebimentoId: data.recebimentoId,
        dataInicioEmbarque: data.dataInicioEmbarque || "0000-00-00 00:00:00",
        dataFimEmbarque: data.dataFimEmbarque || "0000-00-00 00:00:00",
        dataInicioRecebimento:
          data.dataInicioRecebimento || "0000-00-00 00:00:00",
        coletaStatus: data.coletaStatus || "",
        despachanteId: data.despachanteId,
        despachanteNome: data.despachanteNome || "",
        empilhadorId: data.empilhadorId,
        transportadoraId: data.transportadoraId,
        transportadoraNomeFantasia: data.transportadoraNomeFantasia || "",
        motoristaId: data.motoristaId,
        motoristaCpf: data.motoristaCpf || "",
        motoristaNome: data.motoristaNome || "",
        veiculoId: data.veiculoId,
        veiculoPlaca: data.veiculoPlaca || "",
        tipoVeiculoId: data.tipoVeiculoId,
        tipoVeiculoNome: data.tipoVeiculoNome || "",
        docaId: data.docaId,
        docaCor: data.docaCor || "",
        docaCodigo: data.docaCodigo,
        quantidadeVolumesEmbarcados: data.quantidadeVolumesEmbarcados,
        quantidadeVolumesTotal: data.quantidadeVolumesTotal,
        quantidadePedidos: data.quantidadePedidos,
        permiteColetaAssinaturaMotorista:
          !!data.permiteColetaAssinaturaMotorista,
        permiteFinalizarColeta: !!data.permiteFinalizarColeta,
        assinaturaMotoristaUrl: data.assinaturaMotoristaUrl || "",
        pedidos: data.pedidos.map((p) => ({
          pedidoId: p.pedidoId,
          numeroPedido: p.numeroPedido,
          tipoPedido: p.tipoPedido,
          quantidadeVolumesEmbarcados: p.quantidadeVolumesEmbarcados,
          quantidadeVolumesTotal: p.quantidadeVolumesTotal,
        })),
        volumes: data.volumes.map((v) => ({
          volumeId: v.volumeId,
          pedidoId: v.pedidoId,
          codigoBarras: v.codigoBarras || "",
          codigoCarga: v.codigoCarga || "",
          habilitaEditarDetalhes: !!v.habilitaEditarDetalhes,
          permiteBipagem: !!v.permiteBipagem,
          numeroPedido: v.numeroPedido,
          embarcado: !!v.embarcado,
          redespachado: !!v.redespachado,
          cargaId: v.cargaId,
          cargaStatus: v.cargaStatus || "",
          tipoPedido: v.tipoPedido || "",
        })),
      };
      console.log({ coletaDetalhes });
      commit("setColetaDetalhes", coletaDetalhes);
      commit("setIsLoadingReload", false);
      return;
    } else {
      let errorObj = {
        errorStatus: true,
        errorMessage: response.data.message,
      };
      commit("notifications/setErrorNotification", errorObj, { root: true });
      commit("setIsLoadingReload", false);
      return null;
    }
  },

  async findVolumeDetalhesByVolumeId({}, volumeId) {
    try {
      const response = await ExpeditionRepository.findVolumeDetalhesByVolumeId(
        volumeId
      );
      const { data } = response.data;
      return {
        volumeId: data.volumeId,
        status: data.status || "",
        insumos: (data.insumos || []).map((i) => ({
          insumoId: i.insumoId,
          descricao: i.descricao || "",
          linha: (i.linha || "").toUpperCase(),
          serial: i.serial || "",
        })),
      };
    } catch (e) {
      return null;
    }
  },

  async biparVolume({ commit, dispatch }, { coletaId, volume }) {
    commit("setIsLoadingBiparVolume", true);
    let data = {
      coletaId: coletaId,
      // Força tipagem para string, pois de alguma forma sair como inteiro no backend
      // pode gerar estouro de memória ou ainda encurtar o volume para o caso daqueles
      // iniciados por uma sequência de zeros.
      volumes: [`${volume}`],
    };
    const response = await ExpeditionRepository.biparVolume(data);
    if (response.status >= 200 && response.status < 300) {
      commit("setIsLoadingBiparVolume", false);
      return true;
    } else {
      let errorObj = {
        errorStatus: true,
        errorMessage: response.data.message,
      };
      commit("setIsLoadingBiparVolume", false);
      commit("notifications/setErrorNotification", errorObj, { root: true });
      dispatch("reloadColetaDetalhesByColetaId", coletaId);
      return false;
    }
  },

  async biparVolumeLote({ commit, dispatch }, { coletaId, codigo }) {
    commit("setIsLoadingBiparVolume", true);
    let data = {
      coletaId: coletaId,
      // Força tipagem para string, pois de alguma forma sair como inteiro no backend
      // pode gerar estouro de memória ou ainda encurtar o volume para o caso daqueles
      // iniciados por uma sequência de zeros.
      codigo: `${codigo}`,
    };
    const response = await ExpeditionRepository.biparVolumeLote(data);
    if (response.status >= 200 && response.status < 300) {
      commit("setIsLoadingBiparVolume", false);
      return true;
    } else {
      let errorObj = {
        errorStatus: true,
        errorMessage: response.data.message,
      };
      commit("setIsLoadingBiparVolume", false);
      commit("notifications/setErrorNotification", errorObj, { root: true });
      dispatch("reloadColetaDetalhesByColetaId", coletaId);
      return false;
    }
  },

  async finalizaColeta({ commit }, body) {
    try {
      const response = await ExpeditionRepository.finalizarColeta(body);
      let successObj = {
        successStatus: true,
        successMessage: response.data.message,
      };
      if (response.status >= 200 && response.status < 300) {
        commit("notifications/setSuccessNotification", successObj, {
          root: true,
        });
        router.push("/coletas-agendadas/matriz");
        return response;
      } else {
        let errorObj = {
          errorStatus: true,
          errorMessage: response.data.message,
        };
        commit("notifications/setErrorNotification", errorObj, { root: true });
      }
      return response;
    } catch (e) {
      return null;
    }
  },

  async finalizaRecebimento({ commit }, recebimentoId) {
    try {
      const response = await ExpeditionRepository.finalizaRecebimento(
        recebimentoId
      );
      let successObj = {
        successStatus: true,
        successMessage: response.data.message,
      };
      if (response.status >= 200 && response.status < 300) {
        commit("notifications/setSuccessNotification", successObj, {
          root: true,
        });
        return response;
      } else {
        let errorObj = {
          errorStatus: true,
          errorMessage: response.data.message,
        };
        commit("notifications/setErrorNotification", errorObj, { root: true });
      }
      return response;
    } catch (e) {
      return null;
    }
  },
  async capturaBipagem({ commit }, capturarCamera) {
    const response = await ExpeditionRepository.capturaBipagem(capturarCamera);
    if (response.status >= 200 && response.status < 300) {
      let successObj = {
        successStatus: true,
        successMessage: "Captura enviada com sucesso!",
      };
      commit("notifications/setSuccessNotification", successObj, {
        root: true,
      });
      return;
    }
    let errorObj = {
      errorStatus: true,
      errorMessage: response.data.message,
    };
    commit("notifications/setErrorNotification", errorObj, { root: true });
  },
  async volumesComErros({ commit }, coletaId) {
    commit("setVolumesComErros", []);
    const response = await ExpeditionRepository.volmesComErros(coletaId);
    if (response.status >= 200 && response.status < 300) {
      if (response.data.data.statusCode != 200) {
        let errorObj = {
          errorStatus: true,
          errorMessage:
            "Erro de conexão com o servidor ao carregar volumes com erros",
        };
        commit("notifications/setErrorNotification", errorObj, { root: true });
      } else {
        commit("setVolumesComErros", response.data.data.data);
        commit("setModalVolumesComErros", !!response.data.data.data.length);
        return true;
      }
    } else {
      let errorObj = {
        errorStatus: true,
        errorMessage: response.data.message,
      };
      commit("notifications/setErrorNotification", errorObj, { root: true });
    }
  },

  async removeVolumesComErros({ commit }, urlParams) {
    const response = await ExpeditionRepository.removeVolumesComErros(
      urlParams
    );
    if (response.status >= 200 && response.status < 300) {
      let successObj = {
        successStatus: true,
        successMessage: response.data.message,
      };
      commit("notifications/setSuccessNotification", successObj, {
        root: true,
      });
      return;
    } else {
      let errorObj = {
        errorStatus: true,
        errorMessage: response.data.message,
      };
      commit("notifications/setErrorNotification", errorObj, { root: true });
    }
  },
  setDataExpeditionInicial({ commit }, value) {
    commit("setDataExpeditionInicial", value);
  },
  setDataExpeditionFinal({ commit }, value) {
    commit("setDataExpeditionFinal", value);
  },

  setDataFaturamentoInicial({ commit }, value) {
    commit("setDataFaturamentoInicial", value);
  },
  setDataFaturamentoFinal({ commit }, value) {
    commit("setDataFaturamentoFinal", value);
  },

  async listExpeditionUserList({ commit }) {
    commit("setListExpeditionUserList", []);
    const response = await ExpeditionRepository.listExpeditionUserList();
    if (response.status >= 200 && response.status < 300) {
      commit("setListExpeditionUserList", response.data.data);
      return;
    } else {
      let errorObj = {
        errorStatus: true,
        errorMessage: response.data.message,
      };
      commit("notifications/setErrorNotification", errorObj, { root: true });
    }
  },

  async listExpeditionUserListByColetaId({ commit }, coletaId) {
    commit("setListExpeditionUserListByColetaId", []);
    const response =
      await ExpeditionRepository.listExpeditionUserListByColetaId(coletaId);
    if (response.status >= 200 && response.status < 300) {
      commit("setListExpeditionUserListByColetaId", response.data.data);
      return;
    } else {
      let errorObj = {
        errorStatus: true,
        errorMessage: response.data.message,
      };
      commit("notifications/setErrorNotification", errorObj, { root: true });
    }
  },

  async updateDespaschantesDaColeta({ commit }, data) {
    const response = await ExpeditionRepository.updateDespaschantesDaColeta(
      data
    );
    if (response.status >= 200 && response.status < 300) {
      let successObj = {
        successStatus: true,
        successMessage: response.data.message,
      };
      commit("notifications/setSuccessNotification", successObj, {
        root: true,
      });
      return response;
    } else {
      let errorObj = {
        errorStatus: true,
        errorMessage: response.data.message,
      };
      commit("notifications/setErrorNotification", errorObj, { root: true });
      return response;
    }
  },
  async generateManifest({ commit }, data) {
    const urlParams = data;
    let url = `/coleta/listar?page=1&perPage=999999`;
    if (urlParams.search) {
      url = url.concat(`&search=${urlParams.search}`);
    }
    if (urlParams.codigo) {
      url = url.concat(`&codigo=${urlParams.codigo}`);
    }
    if (urlParams.initialDate) {
      url = url.concat(`&initialDate=${urlParams.initialDate}`);
    }
    if (urlParams.finalDate) {
      url = url.concat(`&finalDate=${urlParams.finalDate}`);
    }
    const response = await ExpeditionRepository.requestExpedition(url);
    if (response.status >= 200 && response.status < 300) {
      const coletasConcluidas = response.data.data.list.filter(
        (coleta) => coleta.status == "COLETA_FINALIZADA"
      );
      commit("setListingManifest", coletasConcluidas);
      return;
    }
    let errorObj = {
      errorStatus: true,
      errorMessage: response.data.message,
    };
    commit("setListingManifest", []);
    commit("notifications/setErrorNotification", errorObj, { root: true });
  },

  async cleanListingManifest({ commit }) {
    commit("setListingManifest", []);
  },

  checkListAdd({ commit }, payload) {
    commit("addCheckedList", payload);
  },

  checkListRemove({ commit }, payload) {
    commit("removeCheckedList", payload);
  },

  removeAllCheckList({ commit }, payload) {
    commit("cleanCheckedList", payload);
  },

  async generateManifestRelatorio({ commit }, data) {
    try {
      const response = await ExpeditionRepository.requestExpeditionRelatorio(
        data.data,
        data.useState
      );
      if (response.status >= 200 && response.status < 300) {
        let successObj = {
          successStatus: true,
          successMessage: "Relatório gerado com sucesso.",
        };
        commit("notifications/setSuccessNotification", successObj, {
          root: true,
        });
        return true;
      } else {
        throw new Error("Erro ao gerar o relatório.");
      }
    } catch (error) {
      let errorObj = {
        errorStatus: true,
        errorMessage: "Erro ao gerar o relatório.",
      };
      commit("notifications/setErrorNotification", errorObj, {
        root: true,
      });
      return false;
    }
  },

  async pauseCollection({ commit }, modelPauseCollection) {
    const response = await ExpeditionRepository.requestConfirmPauseCollection(
      modelPauseCollection
    );
    if (response.status >= 200 && response.status < 300) {
      let successObj = {
        successStatus: true,
        successMessage: response.data.message,
      };
      commit("notifications/setSuccessNotification", successObj, {
        root: true,
      });
      return;
    } else {
      let errorObj = {
        errorStatus: true,
        errorMessage: response.data.message,
      };
      commit("notifications/setErrorNotification", errorObj, {
        root: true,
      });
    }
  },

  async protocolData({ commit }, coletaId) {
    commit("setProtocolData", []);
    const response = await ExpeditionRepository.protocolData(coletaId);
    if (response.status >= 200 && response.status < 300) {
      let successObj = {
        successStatus: true,
        successMessage: response.data.message,
      };
      commit("setProtocolData", response.data.data);
      commit("notifications/setSuccessNotification", successObj, {
        root: true,
      });
      return response;
    } else {
      let errorObj = {
        errorStatus: true,
        errorMessage: response.data.message,
      };
      commit("notifications/setErrorNotification", errorObj, { root: true });
    }
  },
  async requestConfirm({ commit }, coletaId) {
    const response = await ExpeditionRepository.requestConfirm(coletaId);
    if (response.status >= 200 && response.status < 300) {
      let successObj = {
        successStatus: true,
        successMessage: "Coleta conferida com sucesso!",
      };
      commit("notifications/setSuccessNotification", successObj, {
        root: true,
      });
      return;
    }
    let errorObj = {
      errorStatus: true,
      errorMessage: response.data.message,
    };
    commit("notifications/setErrorNotification", errorObj, { root: true });
  },

  async pickupOrders({ state, commit }, pickupId) {
    commit("setIsLoadingPickupOrders", true);
    commit("setPickupOrders", []);
    try {
      const response = await ExpeditionRepository.getPickupOrders({
        ...state.pickupOrdersUrlParams,
        coletaId: pickupId,
      });
      if (response.status === 200) {
        const pickupOrders = response.data.data.list;
        const pagination = response.data.data.pagination;
        commit("setPickupOrders", pickupOrders);
        commit("setPickupOrdersPagination", pagination);
        return;
      }
      commit(
        "notifications/setErrorNotification",
        { errorStatus: true, errorMessage: response.data.message },
        { root: true }
      );
    } catch (error) {
      console.log(error);
    } finally {
      commit("setIsLoadingPickupOrders", false);
    }
  },

  async pickupOrdersRedespacho({ state, commit }, pickupId) {
    commit("setIsLoadingPickupOrders", true);
    commit("setPickupOrders", []);
    try {
      const response = await ExpeditionRepository.getPickupOrdersRedespacho({
        ...state.pickupOrdersUrlParams,
        coletaId: pickupId,
      });
      if (response.status === 200) {
        const pickupOrders = response.data.data.list;
        const pagination = response.data.data.pagination;
        commit("setPickupOrders", pickupOrders);
        commit("setPickupOrdersPagination", pagination);
        return;
      }
      commit(
        "notifications/setErrorNotification",
        { errorStatus: true, errorMessage: response.data.message },
        { root: true }
      );
    } catch (error) {
      console.log(error);
    } finally {
      commit("setIsLoadingPickupOrders", false);
    }
  },

  async pickupOrdersRecebimento({ state, commit }, pickupId) {
    commit("setIsLoadingPickupOrders", true);
    commit("setPickupOrders", []);
    try {
      const response = await ExpeditionRepository.getColetaDetalhesRecebimento({
        ...state.pickupOrdersUrlParams,
        coletaId: pickupId,
      });
      if (response.status === 200) {
        const pickupOrders = response.data.data.list;
        const pagination = response.data.data.pagination;
        commit("setPickupOrders", pickupOrders);
        commit("setPickupOrdersPagination", pagination);
        return;
      }
      commit(
        "notifications/setErrorNotification",
        { errorStatus: true, errorMessage: response.data.message },
        { root: true }
      );
    } catch (error) {
      console.log(error);
    } finally {
      commit("setIsLoadingPickupOrders", false);
    }
  },

  async detailsPedido({ commit }, pedidoId) {
    commit("setDetailsPedido", []);
    const response = await ExpeditionRepository.detailsPedido(pedidoId);
    if (response.status >= 200 && response.status < 300) {
      commit("setDetailsPedido", response.data.data);
      return;
    }
    let errorObj = {
      errorStatus: true,
      errorMessage: response.data.message,
    };
    commit("notifications/setErrorNotification", errorObj, { root: true });
  },

  setShowVolumesByStatus({ commit }, payload) {
    commit("setShowPendingVolumes", payload[0]);
    commit("setShowLoadedVolumes", payload[1]);
    commit("setShowAllVolumes", payload[2]);
  },

  setPickupOrdersUrlParams({ commit }, payload) {
    commit("setPickupOrdersUrlParams", payload);
  },

  setIsPickupOrdersModalOpen({ commit }, payload) {
    commit("setIsPickupOrdersModalOpen", payload);
  },

  setCurrentPageToFirstPage({ commit, state }) {
    commit("setPickupOrdersUrlParams", {
      ...state.pickupOrdersUrlParams,
      page: 1,
    });
  },

  async generateReport({ commit }, { body, fileFormat, fileName }) {
    try {
      const response = await ExpeditionRepository.generateReport(
        body,
        fileFormat,
        fileName
      );
      if (response.status === 200) {
        commit(
          "notifications/setSuccessNotification",
          {
            successStatus: true,
            successMessage: "Minuta gerada com sucesso",
          },
          { root: true }
        );
        return;
      }
      commit(
        "notifications/setErrorNotification",
        { errorStatus: true, errorMessage: response.data.message },
        { root: true }
      );
    } catch (error) {
      console.log(error);
    }
  },
};
